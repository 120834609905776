/**
 * RaiD Api Wrapper
 * 
 * Small api that controls audio and analytics
 * 
*/
import { siteMetadata } from "./gatsby-config.js";

const { siteUrl } = siteMetadata;

export const __raid = { 
    
    /**
     * External Audio Object
     * 
     * Used for background audio 
     * See: SoundButton in main app
     * 
     */
    snd: {                          
        el: null,
        isLoaded: false,
        init: function() {
            const audio = document.createElement("audio");
            audio.classList.add("audio-container");
            audio.loop = true;
            audio.autoplay = false;
            audio.preload = "metadata";
            this.el = audio;
            this.onLoadMeta = this.onLoadMeta.bind(this);
        },
        onLoadMeta: function(e) {
            this.el.removeEventListener("loadedmetadata", this.onLoadMeta);
            this.isLoaded = true;
            this.el.play();
        },
        loadSrc: function(src) {
            this.el.src = src;
            this.el.addEventListener("loadedmetadata", this.onLoadMeta);
        }
    },
    
    /**
     * Window Location Object
     * 
     */
    loc: null,
    
    /**
     * Google Analytics Wrapper
     * 
     */
    ga: {
        /**
         * Sends tracking events to Google Analytics
         * 
         * @param {String} evt Google event
         * @param {String} act Google action
         * @param {String} lbl Google label
         */
        trackEvent: function(evt, act, lbl) {

            // Google Analytics is loader after page load at IDLE.  
            // @See dist/utils/loadga_js
            if (window.ga) window.ga('send', 'event', evt, act, lbl);

        }
    },                        

    /**
     * Loads Google Analytics JS Libarry
     * 
     */
    loadAnalytics: function() {

        // console.log("Browser.loadAnalytics()")
        const requestIdleCallback = window.requestIdleCallback ||
        function(cb) {
            const start = Date.now();
            return setTimeout(function() {
                cb({
                    didTimeout: false,
                    timeRemaining: function() {
                        return Math.max(0, 50 - (Date.now() - start));
                    },
                });
            }, 1);
        };

        requestIdleCallback(function()
        {
            const gaJS = document.createElement("script");
            gaJS.id = "__raid_ga"
            gaJS.type = "text/javascript";
            gaJS.defer = true;
            gaJS.src = `${siteUrl}/static/js/ga.js`;
            gaJS.onload = function() {

                window.ga.l=+new Date();
                window.ga('create', 'UA-217792989-1', 'auto');
                window.ga('send', 'pageview');

            };
            document.head.appendChild(gaJS);
        }, {
            timeout:  2500
        });

    }
};
