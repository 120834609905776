// Meta
// import { meta } from "./gatsby-meta.js";

let siteUrl, pathPrefix;

if (process.env.NODE_ENV === 'development') 
{
    pathPrefix = "/";
    siteUrl = "http://localhost:8000";
}
else 
{
    // gatsby serve
    //pathPrefix = "/";
    //siteUrl = "http://localhost:9000";
    
    // raidpr host
    //pathPrefix = "/apps/gatsby";
    //siteUrl = "https://raidpr.com/apps/gatsby";
    
    // live host
    pathPrefix = "/";
    siteUrl = "https://casalupita.net";
}


const ts = Date.now();
const dt = new Date();
const iso = dt.toISOString();

module.exports = {
    pathPrefix: `${pathPrefix}`,
    siteMetadata: {
        siteUrl: `${siteUrl}`,
        siteName: `Casa Lupita`,
        title: `Rehabilitation center specializing in pediatrics, physical and mental health trauma.`,
        description: 'Welcome to “Rehabilitation Redefined.” Casa Lupita, set in a tropical paradise nestled in the hills of the Pacific Coast of Oaxaca.',
        author: '@casalupita',
        lang: `en`,
        locale: `en_US`,
        image: {
            url: `${siteUrl}/static/images/site/casa_logo_seo_1200x630.jpg?mtime=${ts}`, 
            width: 1200, 
            height: 630, 
            alt: 'Casa Lupita logo', 
            updatedTime: iso, 
        },
        video: {
            url: `${siteUrl}/static/videos/casa_lupita_video.mp4?mtime=${ts}`, 
            width: 1920, 
            height: 1080
        },
        social: [
            "https://www.facebook.com/CasaLupitaHux",
            "https://www.instagram.com/CasaLupitaHux",
            "https://www.youtube.com/channel/UCA_aJ8oJuki2X1y52q9jJjw",
        ],
        facebook: {
            profile_id: `100077628604000`,
            app_id: `445904070611998`
        },
        meta: {
            "home": {
                "title": "Fully handicap accessible rehabilitation center.",
                "description": "Specializing in pediatrics as well as physical and mental health trauma, wellness recovery for the whole family",
            },
            "about": {
                "title": "ABOUT US. A five-star luxury boutique hotel experience.",
                "description": "300 meters above Playa Bocana, in Huatulco Mexico, Casa Lupita.",
            },
            "philosophy": {
                "title": "OUR PHILOSOPHY | Bringing the dignity of independence.",
                "description": "Aiming to provide a refuge and sanctuary, while offering an unparalleled five star vacation experience.",
            },
            "rehabilitation": {
                "title": "REHABILITATION SERVICES | Physical, occupational therapy.",
                "description": "Highly trained professionals, including licensed physical therapists, and a Board-Certified Orthopedic Surgeon.",
            },
            "gallery": {
                "title": "GALLERY | Like no Place on Earth. Casa Lupita",
                "description": "Experience a tranquil and emotionally fulfilling memory to last a lifetime.",
            },
            "locale": {
                "title": "LOCALE | Only 50 minutes by air from Mexico City.",
                "description": "On the coast of the state of Oaxaca, at the edge of the Sierra Madre Mountains, Huatulco covers 20 miles of Mexico’s pristine Pacific coastline.",
            },
            "cuisine": {
                "title": "AVAILABLE CUISINE | Casa Lupita",
                "description": "With available Chef service, Casa Lupita offers a wide variety of Oaxacan gourmet and traditional foods, from seafood, to local delicacies.",
            },
            "accommodations": {
                "title": "OUR ACCOMMODATIONS | Casa Lupita",
                "description": "Luxury 4 Bedrooms, 6 Baths and Swimming pool with wheel chair lift.",
            },
            "bookyourstay": {
                "title": "BOOK YOUR STAY | Accessible. Immersive. Unforgettable.",
                "description": "You may book your stay through our reservation agents, My Huatulco Vacation | explorehuatulco.com",
            },
            "contact": {
                "title": "CONTACT US | Visit a verdant tropical paradise.",
                "description": "Our goal is to bring the dignity of independence and mobility to your child or loved one.",
            }
        }
    },
    
    plugins: [
        "gatsby-plugin-sass",
        "gatsby-plugin-react-helmet",
        "gatsby-plugin-sitemap", {
            resolve: 'gatsby-plugin-manifest',
            options: {
                "icon": "src/images/icon.png"
            }
        },
        {
            resolve: "gatsby-plugin-htaccess",
            options: {
                https: true
            }
        },
        {
            resolve: 'gatsby-plugin-svgr-svgo',
            options: {
                inlineSvgOptions: [{
                    test: /\.svg$/,
                    svgoConfig: {
                        plugins: [
                            { name: "prefixIds", active: false },
                            { name: "cleanupIDs", active: false },
                        ],
                    },
                }]
            }
        }
    ]
};