exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accommodations-js": () => import("./../../../src/pages/accommodations.js" /* webpackChunkName: "component---src-pages-accommodations-js" */),
  "component---src-pages-bookyourstay-js": () => import("./../../../src/pages/bookyourstay.js" /* webpackChunkName: "component---src-pages-bookyourstay-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cuisine-js": () => import("./../../../src/pages/cuisine.js" /* webpackChunkName: "component---src-pages-cuisine-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locale-js": () => import("./../../../src/pages/locale.js" /* webpackChunkName: "component---src-pages-locale-js" */),
  "component---src-pages-philosophy-js": () => import("./../../../src/pages/philosophy.js" /* webpackChunkName: "component---src-pages-philosophy-js" */),
  "component---src-pages-rehabilitation-js": () => import("./../../../src/pages/rehabilitation.js" /* webpackChunkName: "component---src-pages-rehabilitation-js" */)
}

