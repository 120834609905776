/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// RaiD Api Wrapper
import { __raid } from "./gatsby-raid.js";

// Global CSS styles
import "./sass/style.scss";

// Set reference to window
window["__raid"] = __raid;

// Called when the Gatsby browser runtime first starts.
export function onClientEntry(arg, pluginOptions) {
    // console.log("Browser.API.onClientEntry(arg)", arg)
    // console.log("Browser.API.onClientEntry(pluginOptions)", pluginOptions)

    // initialize sound
    __raid.snd.init();
}

// Called when changing location is started.
export function onPreRouteUpdate({ location, prevLocation }, pluginOptions) {
    //console.log("Browser.API.onPreRouteUpdate(location)", location);
    //console.log("Browser.API.onPreRouteUpdate(prevLocation)", prevLocation);
    //console.log("Browser.API.onPreRouteUpdate(pluginOptions)", pluginOptions);
    
    // set/save current window.location object
    if (!__raid.loc) __raid.loc = location;
}

// Called when the initial (but not subsequent) render of Gatsby App is done on the client.
export function onInitialClientRender(arg, pluginOptions) {
    //console.log("Browser.API.onInitialClientRender(arg)", arg)
    //console.log("Browser.API.onInitialClientRender(pluginOptions)", pluginOptions)

    // initialize analytics
    __raid.loadAnalytics();
}